<template>
  <div class="content-wrap">
    <div class="content-select">
      <div class="select-content">
        <el-form ref="selectForm" v-model="selectForm" class="select-form">
          <div>
            <el-form-item label="科目代码">
              <el-input v-model.trim="selectForm.code" placeholder="请输入组织名称"
                        maxlength="32"></el-input>
            </el-form-item>
            <el-form-item label="科目名称   ">
              <el-input v-model.trim="selectForm.name" placeholder="请输入组织税号"
                        maxlength="32"></el-input>
            </el-form-item>
          </div>
        </el-form>
        <div class="select-button">
          <select-button @select="handleSelect" @reset="handleReset"></select-button>
        </div>
      </div>
    </div>
    <div class="content-main">
      <div class="content-title">
        <div>
          <span class="content-title-span">{{ organization.name }}</span>
          <el-button @click="shutDetails">返回</el-button>
        </div>
        <el-upload
          action="#"
          accept=".xlsx,.xls"
          multiple
          :show-file-list="false"
          :auto-upload="false"
          :on-change="handleImport">
          <el-button type="primary">导入</el-button>
        </el-upload>
      </div>
      <el-table
        :data="tableData"
        style="width:100%"
        border
        stripe
        v-loading="tableLoading"
        :header-cell-style="handleHeaderCellStyle"
        :default-expand-all="false"
        max-height="440"
      >
        <el-table-column type="index" label="序号" width="60"></el-table-column>
        <el-table-column prop="code" label="科目代码" align="left"></el-table-column>
        <el-table-column prop="name" label="科目名称"></el-table-column>
      </el-table>
      <div class="content-page">
        <el-pagination background
                       @size-change="handleSizeChange"
                       @current-change="handleCurrentChange"
                       :current-page.sync="page.current"
                       :page-sizes="pageSizes"
                       :page-size="page.size"
                       layout="total, sizes, prev, pager, next"
                       :total="page.total">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import {
  dictData, dictImport
} from '@/service/declare/dict'
import { tableStyle, pagingMethods, dateOption, changeMethods } from '@/util/mixins'

export default {
  name: 'EntSubjectDictDetails',
  mixins: [tableStyle, dateOption, pagingMethods, changeMethods],
  props: {
    organization: {
      type: Object
    }
  },
  data () {
    return {
      selectForm: {
        name: undefined,
        code: undefined,
        orgId: undefined
      },
      tableData: [],
      tableLoading: false,
    }
  },
  created () {
    this.submitForm()
  },

  methods: {
    handleSelect () {
      this.submitForm()
    },
    submitForm () {
      this.selectForm.page = this.page.current
      this.selectForm.size = this.page.size
      this.selectForm.orgId = this.organization.id
      this.tableLoading = true
      dictData(this.selectForm).then(res => {
        this.tableLoading = false
        if (res.success) {
          this.tableData = res.data.records
          this.page.total = res.data.total
        }
      })
    },
    //重置
    handleReset () {
      this.selectForm = {
        name: undefined,
        code: undefined
      }
    },
    shutDetails () {
      this.$emit('shutDetails')
    },
    //导入
    handleImport (file) {
      let reg = /\.xlsx?$/gi
      if (!reg.test(file.raw.name)) {
        this.toast('文件格式有误，请重新导入', 'error')
        return
      }
      if (this.tableData.length > 0) {
        this.$confirm('当前组织已存在科目余额表数据，是否覆盖?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.submitImport(file)
        }).catch(() => {
        })
      } else {
        this.submitImport(file)
      }

    },
    submitImport (file) {
      let formData = new FormData()
      formData.append('file', file.raw)
      formData.append('orgId', this.organization.id)
      dictImport(formData).then(res => {
        if (res.success) {
          if (res.message.length > 0) {
            this.toast(res.message, 'error')
          } else {
            this.toast('导入成功', 'success')
            this.submitForm()
          }
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
  @import "@/style/select.scss";

  .select-header {
    display: flex;
    flex-direction: row;
    height: 24px;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #e9e9e9;
    padding: 16px 24px;

    .select-items {
      display: inline-flex;

      div {
        margin-right: 10px;
        color: #666666;
        font-size: 14px;
        font-weight: 400;
      }

      input {
        border: none;
      }
    }
  }

  .select-title span:before {
    content: "|";
    display: inline-block;
    font-size: 16px;
    margin-right: 10px;
    background-color: #333;
  }

  .content-main {
    margin: 24px 0 0 0;

    .content-title {
      font-size: 20px;
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 24px;
      justify-content: space-between;

      .content-title-span {
        margin-right: 40px;
      }
    }

    .content-table {
      ::v-deep .el-button {
        padding: 0;
      }
    }
  }

  ::v-deep .el-table td,
  ::v-deep .el-table th {
    text-align: left;
    padding: 8px 0;
  }
</style>
